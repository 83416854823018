.animated-card {
    width: 400px;
    height: 350px;
    padding: 50px 20px 30px 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    position: relative; /* Needed for absolute positioning */
  }
  
  .image-container {
    vertical-align: middle;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .image-static{
    max-width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    transition: opacity 1s ease; /* 1-second fade-in and fade-out effect */
  }
  
  .image-animated {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    transition: opacity 1s ease; /* 1-second fade-in and fade-out effect */
  }
  
  /* Apply the fade-in effect when the card is hovered */
  .animated-card.hovered .image-animated {
    opacity: 1;
  }
  
  /* Hide the animated image when the card is not hovered */
  .animated-card:not(:hover) .image-animated {
    opacity: 0;
  }
  
  .card-content {
    margin-top: 10px; /* Add some margin to separate the text from the image */
  }
  